/* src/components/About.css */

.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    color: #e0e0ff;
    text-align: center;
}

.about-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #00aaff;
}

.about-subtitle {
    font-size: 1.2rem;
    color: #c0c0d0;
    margin-bottom: 30px;
}

.about-section {
    text-align: left;
    margin-top: 20px;
}

.about-heading {
    font-size: 1.8rem;
    color: #00aaff;
    margin: 20px 0;
}

.about-text {
    font-size: 1rem;
    color: #e0e0ff;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-list {
    list-style-type: none;
    padding-left: 0;
    color: #c0c0d0;
    font-size: 1rem;
}

.about-list li {
    margin-bottom: 10px;
}

.about-contact-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #00aaff;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.about-contact-link:hover {
    background-color: #008ac7;
}