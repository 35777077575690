/* src/components/Testimonial.css */

.testimonial-section {
    padding: 60px 20px; /* Padding for spacing */
    background: 
      linear-gradient(to bottom, #000000, #1d003b), /* Background gradient */
      repeating-linear-gradient(
        to right, 
        rgba(255, 255, 255, 0.1) 0, 
        rgba(255, 255, 255, 0.1) var(--line-thickness), 
        transparent var(--line-thickness), 
        transparent var(--line-spacing)
      ),
      repeating-linear-gradient(
        to bottom, 
        rgba(255, 255, 255, 0.1) 0, 
        rgba(255, 255, 255, 0.1) var(--line-thickness), 
        transparent var(--line-thickness), 
        transparent var(--line-spacing)
      );
    background-blend-mode: overlay; /* Blending effect */
    color: #ffffff; /* White text for visibility */
    text-align: center; /* Center text */
}

h2 {
    font-size: 2.5rem; /* Larger font size for the heading */
    margin-bottom: 30px; /* Increased margin for better spacing */
    color: #00aaff; /* Bright color for visibility */
    font-family: 'Arial', sans-serif; /* Clean font */
}

.testimonial-container {
    display: flex;
    justify-content: center; /* Center the testimonials */
    flex-wrap: wrap; /* Allow wrapping to the next line */
    gap: 30px; /* Increased gap for spaciousness */
}

.testimonial-card {
    background: #1d003b; /* Dark card background for contrast */
    border-radius: 10px; /* Softer corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Slightly larger shadow for depth */
    padding: 25px; /* More padding for better readability */
    max-width: 320px; /* Slightly larger card width */
    flex: 1 1 30%; /* 3 cards in a row */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
    margin: 10px; /* Margin for spacing */
}

.testimonial-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Darker shadow on hover */
}

.testimonial-text {
    font-style: italic; /* Italicize the testimonial text */
    margin-bottom: 15px; /* Space between text and name */
    font-size: 1.1rem; /* Slightly larger text for readability */
    color: #cccccc; /* Soft light gray color */
}

.testimonial-name {
    font-size: 1.3rem; /* Increased font size for names */
    font-weight: bold; /* Bold text for emphasis */
    margin-bottom: 5px; /* Space below name */
    color: #00aaff; /* Bright color for visibility */
}

.testimonial-location {
    font-size: 0.9rem; /* Smaller font size for location */
    color: #777; /* Light gray for less emphasis */
    margin-top: 5px; /* Space above location */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .testimonial-card {
        flex: 1 1 100%; /* Stacks cards on smaller screens */
    }
}

@media (max-width: 576px) {
    h2 {
        font-size: 2rem; /* Smaller heading size for mobile */
    }
    .testimonial-text {
        font-size: 1rem; /* Adjust testimonial text size */
    }
}