/* Full-page loader container */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background with transparency */
    z-index: 9999; /* Ensure the loader stays on top */
    transition: opacity 0.3s ease-in-out; /* Smooth fade-out transition */
  }
  
  /* Loader Spinner */
  .loader {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light border for background */
    border-top: 8px solid #00aaff; /* Bright color for the spinning part */
    border-radius: 50%; /* Circle shape */
    width: 60px; /* Size of the spinner */
    height: 60px;
    animation: spin 1.5s linear infinite; /* Smooth spinning animation */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Adding subtle shadow around spinner */
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Start from 0 degrees */
    }
    100% {
      transform: rotate(360deg); /* Spin full circle */
    }
  }
  
  /* Optional: Add a subtle pulse effect for additional styling */
  @keyframes pulse {
    0% {
      transform: scale(0.9);
      opacity: 0.8;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.9;
    }
  }
  
  /* Pulse effect on hover */
  .loader-container:hover .loader {
    animation: pulse 1s ease-in-out infinite; /* Pulse effect when hovered */
  }
  
  /* Optional: Fade out effect when loading is done */
  .loader-container.fade-out {
    opacity: 0;
    pointer-events: none;
    transition: opacity 3s ease-out;
  }
  
  /* General Body */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* Base container for layout */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Responsive Styles */
  
  /* Desktop */
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
      padding: 30px;
    }
  
    .loader {
      width: 80px;
      height: 80px;
    }
  }
  
  /* Large Tablets (10.1" to 12.9") */
  @media (max-width: 1199px) and (min-width: 768px) {
    .container {
      max-width: 900px;
    }
  
    .loader {
      width: 70px;
      height: 70px;
    }
  }
  
  /* Tablets (7" to 10.1") */
  @media (max-width: 768px) and (min-width: 576px) {
    .container {
      max-width: 600px;
      padding: 15px;
    }
  
    .loader {
      width: 60px;
      height: 60px;
    }
  
    /* Responsive adjustments for text */
    h1 {
      font-size: 2.5rem;
    }
  }
  
  /* Mobile (Smartphones in portrait mode) */
  @media (max-width: 575px) {
    .container {
      max-width: 100%;
      padding: 10px;
    }
  
    .loader {
      width: 50px;
      height: 50px;
    }
    /* Responsive adjustments for text */
    h1 {
      font-size: 2rem;
    }
  }
  /* Mobile (Small smartphones in portrait mode) */
  @media (max-width: 375px) {
    .container {
      padding: 5px;
    }
  
    .loader {
      width: 40px;
      height: 40px;
    }
  
    /* Responsive adjustments for text */
    h1 {
      font-size: 1.6rem;
    }
  }
  
  /* For specific large screens (extra-large desktops) */
  @media (min-width: 1600px) {
    .container {
      max-width: 1400px;
    }
    .loader {
      width: 100px;
      height: 100px;
    }
  }