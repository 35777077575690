/* src/components/Header.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
    position: sticky;
    top: 0; /* Add top property for sticky positioning */
    z-index: 1000; /* Ensures navbar stays above other content */
}

.logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
    background-color: rgb(206, 209, 225);
}
.logo img{
    width: 80px;
    height: 80px;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-links a {
    margin: 0 1rem;
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
}

.nav-links a:hover {
    color: #00aaff; /* Hover color for links */
}

.get-started {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #00aaff; /* Border color for button */
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.get-started:hover {
    background-color: #00aaff; /* Hover effect */
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .navbar {
        padding: 1rem; /* Reduced padding */
    }

    .logo {
        font-size: 1.4rem; /* Slightly smaller logo */
    }

    .nav-links a {
        margin: 0 0.8rem; /* Reduced margin for nav links */
    }

    .get-started {
        padding: 0.4rem 0.8rem; /* Reduced padding */
    }
}

@media (max-width: 992px) {
    .navbar {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
        padding: 1rem; /* Adjust padding */
    }

    .nav-links {
        margin-top: 1rem; /* Space between logo and nav links */
        width: 100%; /* Full width */
        justify-content: space-between; /* Space links out */
    }

    .nav-links a {
        margin: 0; /* Remove margin */
    }

    .get-started {
        width: 100%; /* Full width button */
        text-align: center; /* Center text */
    }
}

@media (max-width: 768px) {
    .logo {
        font-size: 1.2rem; /* Smaller logo size */
    }

    .nav-links a {
        font-size: 0.9rem; /* Smaller font size for links */
    }

    .get-started {
        padding: 0.3rem 0.6rem; /* Smaller padding */
    }
}

@media (max-width: 576px) {
    .navbar {
        padding: 0.5rem; /* Less padding for mobile */
    }

    .logo {
        font-size: 1rem; /* Smallest logo size */
    }

    .nav-links a {
        font-size: 0.8rem; /* Even smaller font size */
        margin: 0 0.5rem; /* Adjust margins */
    }

    .get-started {
        padding: 0.2rem 0.5rem;
        width: 90px; /* Minimal padding */
    }
}