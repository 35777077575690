/* FAQ Container */
.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
  }
  
  .faq-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #0056b3;
  }
  
  /* FAQ Grid */
  .faq-grid {
    display: grid;
    grid-template-columns: repeat(minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* FAQ Item */
  .faq-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    overflow: hidden;
  }
  
  .faq-item:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }
  
  .faq-item.open {
    border-left: 5px solid #0056b3;
  }
  
  /* FAQ Question */
  .faq-question {
    font-size: 1.2rem;
    font-weight: 600;
    color: #222;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* FAQ Answer */
  .faq-answer {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.3s ease;
    transform: translateY(-20px);
  }
  
  .faq-show {
    max-height: 200px; /* Adjust based on your answer's length */
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Icon Animation */
  .faq-question::after {
    content: '+';
    font-size: 1.5rem;
    color: #0056b3;
    transition: transform 0.3s ease-in-out;
  }
  
  .faq-item.open .faq-question::after {
    transform: rotate(45deg);
  }
  
  /* Glow Effect on Active FAQ */
  .faq-item.open {
    box-shadow: 0 0 10px rgba(0, 86, 179, 0.3);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .faq-title {
      font-size: 2rem;
    }
  
    .faq-question {
      font-size: 1.1rem;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .faq-container {
      padding: 15px;
    }
  
    .faq-title {
      font-size: 1.8rem;
    }
  
    .faq-question {
      font-size: 1rem;
    }
  
    .faq-answer {
      font-size: 0.85rem;
    }
  }