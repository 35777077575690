
body, .App {
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #000000, #1d003b);
  color: #ffffff;
}
.whatsapp-icon {
  position: sticky;  /* Sticky positioning */
  bottom: 20px;      /* Distance from the bottom */
  right: 20px;       /* Distance from the right edge */  /* WhatsApp brand color */
  border-radius: 50%;  /* Circle shape */
  padding: 10px;  /* Space around the icon */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Shadow effect */
  transition: transform 0.3s ease; /* Hover effect */
  z-index: 100; /* Ensures the icon stays above other content */
}

.whatsapp-icon:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.whatsapp-icon svg {
  display: block;
  margin: auto; /* Center the icon inside the circle */
}