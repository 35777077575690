
.footer {
    background-color: #2c2c34; /* Dark background for the footer */
    color: #e0e0ff; /* Light text color */
    padding: 40px 20px; /* Padding for spacing */
    text-align: center;
    margin-top: 30px;
}

.footer-content {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space items evenly */
    align-items: flex-start; /* Align items to the top */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    margin-bottom: 20px; /* Space below footer content */
}

.footer-links, .footer-social, .footer-newsletter {
    flex: 1; /* Allow sections to grow equally */
    margin: 10px; /* Space between sections */
}

.footer-links h3, .footer-social h3, .footer-newsletter h3 {
    color: #00aaff; /* Highlight color for headings */
    margin-bottom: 10px; /* Space below headings */
}

.footer-links ul {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
}

.footer-links li {
    margin: 8px 0; /* Space between links */
}

.footer-links a {
    color: #e0e0ff; /* Light link color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth color transition */
}

.footer-links a:hover {
    color: #00aaff; /* Highlight color on hover */
}

.footer-social {
    text-align: center; /* Center align social icons */
}

.social-icons {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center social icons */
    gap: 15px; /* Space between icons */
}

.social-icons a {
    color: #e0e0ff; /* Light color for social icons */
    font-size: 1.5rem; /* Size for icons */
    transition: color 0.3s; /* Smooth color transition */
}

.social-icons a:hover {
    color: #00aaff; /* Highlight color on hover */
}

.footer-newsletter {
    margin-top: 20px; /* Space above newsletter section */
}

.newsletter-form {
    display: flex; /* Use flexbox for form layout */
    justify-content: center; /* Center align the form */
    margin-top: 10px; /* Space above the form */
}

.newsletter-form input {
    padding: 10px; /* Padding for input */
    border: none; /* Remove border */
    border-radius: 5px 0 0 5px; /* Rounded corners */
    outline: none; /* Remove outline */
}

.newsletter-form button {
    padding: 10px 15px; /* Padding for button */
    background-color: #00aaff; /* Button background color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 0 5px 5px 0; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
}

.newsletter-form button:hover {
    background-color: #008ac7; /* Darker button color on hover */
}

.footer-bottom {
    margin-top: 20px; /* Space above bottom text */
    font-size: 0.9rem; /* Smaller font size for bottom text */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack sections vertically */
        align-items: center; /* Center align items */
    }

    .footer-links, .footer-social, .footer-newsletter {
        margin: 20px 0; /* Adjust margin for smaller screens */
        width: 100%; /* Full width for sections */
    }
}