/* src/components/Team.css */
.team-container {
    margin: 0 auto;
    padding: 40px 20px;
    color: #ffffff;
}

.team-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #e0e0ff;
    text-align: center;
    margin-bottom: 20px;
}

/* Grid layout for the team members */
.team-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for larger screens */
    gap: 20px; /* Space between team cards */
}

/* Style for each team card */
.team-card {
    background: linear-gradient(145deg, #2c2c34, #1f1f26);
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative; /* For icon hover effects */
}

.team-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
}

/* Image for each team member */
.team-image {
    width: 80%;
    height: 250px;
    border-radius: 50%; /* Circular image */
    object-fit: cover;
    margin-bottom: 15px;
}

/* Team member name and role */
.team-name {
    font-size: 1.5rem;
    color: #00aaff;
    margin-bottom: 5px;
}

.team-role {
    font-size: 1rem;
    color: #c0c0d0;
    margin-bottom: 15px;
}

/* Social icons */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between icons */
}

.social-icon {
    color: #00aaff;
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
    color: #ffffff;
    transform: scale(1.2); /* Scale animation on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .team-title {
        font-size: 2rem;
    }

    .team-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
}

@media (max-width: 480px) {
    .team-grid {
        grid-template-columns: 1fr; /* 1 column on very small screens */
    }
}