/* Product Page Styles */
.product-page1 {
    padding: 40px;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #0f0f10, #1c1c1e); /* Updated background gradient */
    color: #ffffff; /* White text color */
    text-align: center;
}

h1 {
    font-size: 2.5rem;
    color: #ffffff; /* White color for the title */
    margin-bottom: 20px;
}

/* Container for all the product cards */
.product-cards-container1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between product cards */
}

/* Style for individual product cards */
.product-card1 {
    background: #333; /* Dark background for the product card */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 2rem; /* Padding inside each card */
    width: 300px; /* Fixed width for the card */
    text-align: left; /* Align text to the left */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

/* Hover effects on product cards */
.product-card1:hover {
    transform: translateY(-10px); /* Slightly lift the card on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow */
}

/* Title of the product */
.product-card1 h2 {
    font-size: 1.8rem;
    color: #00aaff; /* Blue color for the product name */
}

/* Product description text */
.product-card1 p {
    font-size: 1rem;
    color: #ffffff; /* White color for description */
    line-height: 1.5; /* Spacing between lines */
}

/* Price section inside product card */
.product-card1 .price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
}

/* Highlighting the price value */
.product-card1 .price span {
    color: #ff9900; /* Orange color for the price */
}

/* Features list for each product */
.product-card1 ul {
    margin-left: 20px;
    font-size: 1rem;
}

/* List items for the features */
.product-card1 ul li {
    color: #bbbbbb; /* Light gray color for feature text */
    margin-bottom: 10px;
}

/* Contact button for inquiries */
.contact-btn {
    background-color: #00aaff; /* Blue background for the button */
    padding: 12px 25px;
    font-size: 1rem;
    color: #ffffff; /* White text color */
    border-radius: 25px; /* Rounded corners */
    text-align: center;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for the contact button */
.contact-btn:hover {
    background-color: #008ac7; /* Darker blue on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Adjusting the product card layout for smaller screens */
    .product-card1 {
        width: 80%;
    }

    /* Stack the product cards vertically */
    .product-cards-container1 {
        flex-direction: column;
        align-items: center; /* Center-align items */
    }
}