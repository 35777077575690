.contact-container {
    max-width: 800px; /* Maximum width for the contact section */
    margin: 0 auto; /* Center the container */
    padding: 40px 20px; /* Padding for spacing */
    color: #e0e0ff; /* Light text color */
    background-color: #2c2c34; /* Dark background for the contact section */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    opacity: 0; /* Start invisible for fade-in effect */
    transform: translateY(20px); /* Start slightly lower */
    animation: fadeInUp 0.6s forwards; /* Fade-in and slide-up animation */
}

@keyframes fadeInUp {
    to {
        opacity: 1; /* End state visible */
        transform: translateY(0); /* End state at original position */
    }
}

.contact-title {
    text-align: center; /* Center align title */
    font-size: 2.5rem; /* Large title font size */
    margin-bottom: 30px; /* Space below title */
    color: #00aaff; /* Highlight color for title */
    animation: slideIn 0.5s ease-in-out forwards; /* Animation for title */
}

@keyframes slideIn {
    0% {
        transform: translateX(-50px); /* Start from left */
        opacity: 0; /* Start invisible */
    }
    100% {
        transform: translateX(0); /* End at original position */
        opacity: 1; /* End state visible */
    }
}

.contact-info {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space items evenly */
    margin-bottom: 40px; /* Space below contact info */
}

.info-item {
    flex: 1; /* Allow items to grow equally */
    margin: 0 20px; /* Space between info items */
    text-align: center; /* Center align text */
    animation: fadeIn 0.6s ease forwards; /* Fade-in animation for info items */
}

@keyframes fadeIn {
    from {
        opacity: 0; /* Start invisible */
        transform: translateY(10px); /* Start slightly lower */
    }
    to {
        opacity: 1; /* End state visible */
        transform: translateY(0); /* End state at original position */
    }
}

.info-item h3 {
    color: #00aaff; /* Highlight color for headings */
}

.contact-form {
    display: flex; /* Use flexbox for form layout */
    flex-direction: column; /* Stack form fields vertically */
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 20px; /* Space below form fields */
    padding: 15px; /* Increased padding for larger input fields */
    border: 1px solid #555; /* Border color */
    border-radius: 5px; /* Rounded corners */
    background-color: #3b3b3f; /* Input field background color */
    color: #e0e0ff; /* Light text color */
    font-size: 1rem; /* Consistent font size */
    transition: border-color 0.3s; /* Smooth border color transition */
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #00aaff; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.contact-form textarea {
    resize: vertical; /* Allow vertical resizing only */
    height: 150px; /* Default height for the textarea */
}

.submit-button {
    padding: 10px; /* Padding for button */
    background-color: #00aaff; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth background color and scaling transition */
}

.submit-button:hover {
    background-color: #008ac7; /* Darker button color on hover */
    transform: scale(1.05); /* Slight scaling on hover for emphasis */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-info {
        flex-direction: column; /* Stack contact info vertically */
        align-items: center; /* Center align items */
    }

    .info-item {
        margin: 20px 0; /* Adjust margin for smaller screens */
    }
}