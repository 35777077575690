/* src/components/Project.css */

.container {
    margin: 0 auto;
    padding: 40px 20px;
    color: #e0e0ff;
}

.projects-section {
    text-align: center;
}

.projects-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #e0e0ff;
    margin-bottom: 20px;
}

/* Grid layout for the projects */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 columns for 4 projects */
    gap: 20px; /* Space between project cards */
}

/* Animation: project card entry fade-in */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Style for each Project card */
.project-card {
    background: linear-gradient(145deg, #2c2c34, #1f1f26);
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    padding: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 0; /* Start invisible */
    animation: fadeInUp 0.8s ease forwards; /* Fade-in effect */
    animation-delay: calc(var(--index) * 0.2s); /* Staggered effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
}

/* Hover effect */
.project-card:hover {
    transform: translateY(-10px); /* Move up slightly */
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.4); /* Deepen shadow */
}

/* Image for each Project */
.project-image {
    width: 100%;
    height: 150px;
    border-radius: 15px;
    object-fit: cover;
    margin-bottom: 15px;
    transition: transform 0.3s ease; /* Smooth zoom on hover */
}

.project-card:hover .project-image {
    transform: scale(1.05); /* Slight zoom */
}

/* Project title and description */
.project-title {
    font-size: 1.3rem;
    color: #00aaff;
    margin-bottom: 10px;
    text-align: center;
}

.project-description {
    font-size: 0.85rem;
    color: #c0c0d0;
    text-align: center;
    margin-bottom: 15px;
}

/* Link for "View Project" */
.project-link {
    color: #00aaff;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
}

.project-link:hover {
    color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .projects-title {
        font-size: 2rem;
    }

    .projects-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }

    .project-card {
        width: 90%;
        margin: 0 auto 20px auto;
    }
}

@media (max-width: 480px) {
    .projects-grid {
        grid-template-columns: 1fr; /* 1 column on very small screens */
    }
}