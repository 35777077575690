
.container {
    background: linear-gradient(135deg, #0f0f10, #1c1c1e);
    color: #ffffff;
    padding: 60px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 2.5rem;
    color: #e0e0ff;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #a0a0b0;
    margin-bottom: 50px;
  }
  
  /* View All Button */
  .button {
    background-color: #ffffff;
    color: #0f0f10;
    padding: 8px 18px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.1);
    border: none;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .button:hover {
    background-color: #d0d0ff;
  }
  
  .section {
    position: relative;
    margin-top: 50px;
    padding: 30px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  }
  
  .service-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Card Style */
  .card {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 25px;
    width: 260px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  }
  
  .card h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #d0d0ff;
  }
  
  .card p {
    font-size: 0.85rem;
    color: #c0c0d0;
  }
  
  .card a {
    color: #d0d0ff;
    font-weight: bold;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .card a:hover {
    color: #ffffff;
  }
  
  /* Custom Colors and Gradient Backgrounds for Each Card */
  .card.web-dev {
    background: linear-gradient(135deg, #4a47a3, #5c5fc1);
  }
  
  .card.ui-ux {
    background: linear-gradient(135deg, #2d2d42, #3e3e57);
  }
  
  .card.digital-marketing {
    background: linear-gradient(135deg, #1c1c1e, #2e2e35);
  }
  
  .card.app-dev {
    background: linear-gradient(135deg, #6a4df3, #7b63f7);
  }
  
  /* Icon Circle in Card */
  .icon {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }