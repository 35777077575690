.cost-predictor {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #e0e0e0; /* Light border for distinction */
}

h1.title {
  text-align: center;
  font-size: 2.2em;
  color: #4a90e2; /* Blue color */
  margin-bottom: 20px;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.website-selection,
.features-selection,
.tech-stack-selection,
.cost-summary {
  margin-bottom: 25px;
}

label {
  display: block;
  margin: 10px 0;
  font-size: 1.1rem;
  color: #333;
}

input[type="checkbox"],
select {
  margin-right: 10px;
}

select {
  padding: 10px;
  border: 1px solid #ddd; /* Light border for select */
  border-radius: 5px;
  font-size: 1rem;
}

input[type="checkbox"] {
  transform: scale(1.2); /* Slightly larger checkboxes */
}

button.contact-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background: #ff6f61; /* Orange color */
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.contact-btn:hover {
  background: #e55b4e; /* Darker shade of orange */
}

.products-section {
  text-align: center;
  margin: 20px 0;
}

.products {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.product-card {
  padding: 20px;
  background: #f9fafb; /* Very light gray */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 180px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin: 10px;
}

.product-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px); /* Hover effect */
}

.product-card h3 {
  margin-bottom: 12px;
  font-size: 1.2rem;
  color: #4a90e2; /* Blue for product titles */
}

.product-card p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.product-card a {
  display: block;
  margin-top: 10px;
  color: #ff6f61; /* Orange color for links */
  text-decoration: none;
  font-weight: bold;
}

.product-card a:hover {
  text-decoration: underline;
}

.cost-summary p {
  font-size: 1.1rem;
  color: #333;
}

h2 {
  color: #333;
}

h3 {
  color: #4a90e2; /* Blue for headings */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .cost-predictor {
    max-width: 700px;
    padding: 20px;
  }

  .product-card {
    width: 150px;
  }
}

@media (max-width: 992px) {
  .cost-predictor {
    max-width: 600px;
    padding: 15px;
  }

  .product-card {
    width: 120px;
  }

  h1.title {
    font-size: 1.8em;
  }

  select {
    font-size: 0.9rem;
  }

  button.contact-btn {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .cost-predictor {
    max-width: 90%;
    padding: 15px;
  }

  .product-card {
    width: 100%;
    margin: 10px 0;
  }

  .products {
    flex-direction: column;
    align-items: center;
  }

  h1.title {
    font-size: 1.6em;
  }

  select,
  input[type="checkbox"] {
    width: 100%;
    font-size: 0.9rem;
  }

  button.contact-btn {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .cost-predictor {
    max-width: 80%;
    padding: 10px;
  }

  h1.title {
    font-size: 1.4em;
  }

  .product-card {
    width: 100%;
  }

  select,
  input[type="checkbox"] {
    font-size: 0.8rem;
  }

  button.contact-btn {
    font-size: 1rem;
  }
}