/* Tech Stack Page Styles */
.tech-stack-page {
    padding: 40px;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #0f0f10, #1c1c1e); /* Dark background */
    color: #ffffff;
    text-align: center;
}

h1 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

h2 {
    font-size: 2rem;
    color: #00aaff; /* Accent color for subheading */
    margin-bottom: 20px;
}

.tech-stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Space between tech cards */
}

.tech-card {
    background: #333;
    padding: 30px;
    border-radius: 10px;
    width: 220px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tech-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.tech-icon {
    font-size: 3rem;
    color: #00aaff; /* Tech icon color */
    margin-bottom: 20px;
}

.tech-name {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 10px;
}

.tech-description {
    font-size: 1rem;
    color: #bbb;
    line-height: 1.4;
}

@media (max-width: 768px) {
    .tech-card {
        width: 100%;
        max-width: 250px;
    }

    .tech-stack-container {
        flex-direction: column;
        align-items: center;
    }
}