:root {
    --line-color: rgba(255, 255, 255, 0.1);
    --line-spacing: 60px; /* Adjust this value to increase/decrease spacing */
    --line-thickness: 2px; /* Thickness of the lines */
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    text-align: center;
    color: #ffffff;
    background: 
      linear-gradient(to bottom, #000000, #1d003b),
      repeating-linear-gradient(
        to right, 
        var(--line-color) 0, 
        var(--line-color) var(--line-thickness), 
        transparent var(--line-thickness), 
        transparent var(--line-spacing)
      ),
      repeating-linear-gradient(
        to bottom, 
        var(--line-color) 0, 
        var(--line-color) var(--line-thickness), 
        transparent var(--line-thickness), 
        transparent var(--line-spacing)
      );
    background-blend-mode: overlay;
    position: relative;
    overflow: hidden;
}

.tagline-carousel {
    position: absolute;
    top: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

/* Keyframes for text animations */
@keyframes scroll {
    0% {
        transform: translateX(100%) scale(1) rotate(0deg);
        font-size: 2.5rem;
        color: #00aaff;
        font-weight: normal;
        text-shadow: none;
    }
    50% {
        transform: translateX(0%) scale(1.5) rotate(0deg); /* Center of screen */
        font-size: 2.5rem;
        color: #ffdd00;
        font-weight: bold;
        text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
    }
    100% {
        transform: translateX(-100%) scale(1) rotate(0deg);
        font-size: 2.5rem;
        color: #00aaff;
        font-weight: normal;
        text-shadow: none;
    }
}

.tagline-carousel p {
    display: inline-block;
    font-size: 1rem;
    color: #00aaff;
    animation: scroll 9s linear infinite;
    white-space: nowrap;
    padding-left: 100%;
    transform-origin: center;
}

.hero-section h1 {
    font-size: 3rem;
    margin-top: 3rem;
}

.hero-section span {
    color: #00aaff;
}

.hero-subtext {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #cccccc;
}

.work-with-us {
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #00aaff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 2rem;
    transition: background-color 0.3s;
}

.work-with-us:hover {
    background-color: #008ac7;
}

/* New Predict Website Cost Button */
.predict-cost-btn {
    background-color: #ff6f61; /* Attractive orange color */
    color: #ffffff;
    padding: 12px 25px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease;
}

.predict-cost-btn:hover {
    background-color: #e55b4e; /* Darker orange on hover */
    transform: scale(1.05); /* Slight zoom effect */
}

.predict-cost-btn:focus {
    outline: none; /* Remove outline when focused */
}

.social-icons {
    display: flex;
    gap: 1rem;
}

.social-icons a {
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #00aaff;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .hero-section h1 {
        font-size: 2.5rem;
    }
    .hero-subtext {
        font-size: 1.1rem;
    }
    .tagline-carousel p {
        font-size: 0.9rem;
    }
    .predict-cost-btn {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

@media (max-width: 992px) {
    .hero-section {
        min-height: 80vh;
    }
    .hero-section h1 {
        font-size: 2rem;
    }
    .hero-subtext {
        font-size: 1rem;
    }
    .tagline-carousel p {
        font-size: 0.8rem;
    }
    .predict-cost-btn {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

@media (max-width: 768px) {
    .hero-section {
        min-height: 70vh;
    }
    .hero-section h1 {
        font-size: 1.8rem;
    }
    .hero-subtext {
        font-size: 0.9rem;
    }
    .tagline-carousel p {
        font-size: 0.7rem;
    }
    .predict-cost-btn {
        font-size: 1rem;
        padding: 9px 18px;
    }
}

@media (max-width: 576px) {
    .hero-section {
        min-height: 60vh;
    }
    .hero-section h1 {
        font-size: 1.6rem;
    }
    .hero-subtext {
        font-size: 0.8rem;
    }
    .tagline-carousel p {
        font-size: 0.6rem;
    }
    .predict-cost-btn {
        font-size: 1rem;
        padding: 9px 18px;
    }
}